import { jwtDecode } from 'jwt-decode';

export function storeToken(token: string): void {
  localStorage.setItem('token', token);
}

export function removeToken(): void {
  localStorage.removeItem('token');
}

export function getToken(): string | null {
  return localStorage.getItem('token');
}

function isTokenExpired(token: string) {
  const currentSecondsSinceEpoch = Date.now() / 1000;
  try {
    const decoded = jwtDecode(token);
    if (typeof decoded.exp !== 'number') {
      throw new Error('Invalid token: exp field is not a number.');
    }
    return decoded.exp < currentSecondsSinceEpoch;
  } catch (error) {
    console.error('Error decoding token:', error);
    return true; // Assume the token is expired if it cannot be decoded
  }
}

export function authTokenAvailable() {
  const token = getToken();
  return token !== null && !isTokenExpired(token);
}
