import axios, { InternalAxiosRequestConfig } from 'axios';
import { getToken } from './tokens';
import config from 'src/config';

function addAuthHeader(config: InternalAxiosRequestConfig<any>) {
  const token = getToken();
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
}

const apiClient = axios.create({
  baseURL: config.apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use((config) => {
  console.debug('Making request to:', config.url);
  return addAuthHeader(config);
});

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.data) {
      error.name = 'API Error';
      error.message = `${error.response.data.message} (${error.response.status})`;
    }
    return Promise.reject(error);
  },
);

export default apiClient;
