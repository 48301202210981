import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Hidden,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../Logo/Logo';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router';

const Navbar = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <AppBar position="static">
      <Toolbar>
        <Hidden mdUp>
          <IconButton edge="start" color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Hidden smDown>
          <IconButton edge="end" color="inherit" aria-label="logo">
            <Logo color={theme.palette.common.white} />
          </IconButton>
        </Hidden>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          Blood Donor Market
        </Typography>
        <Hidden mdUp>
          <IconButton edge="start" color="inherit" aria-label="logo">
            <Logo />
          </IconButton>
        </Hidden>
        <Hidden smDown>
          <Button color="inherit" onClick={() => navigate('/login')}>
            Login
          </Button>
          <Button color="inherit" onClick={() => navigate('/register')}>
            Sign Up
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
