import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from 'src/pages/HomePage';
import LoginPage from 'src/pages/authentication/Login3';
import RegisterPage from 'src/pages/authentication/Register3';
import UserProfile from 'src/pages/user/UserProfile';
import Customers from 'src/pages/donor-search/customers';
import DonorSearch from 'src/pages/donor-locations/DonorSearch';
import { AuthProvider } from 'src/contexts/auth-context';

const RoutesComponent = () => (
  <Router>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route
        path="/user/profile"
        element={
          <AuthProvider>
            <UserProfile />
          </AuthProvider>
        }
      />
      <Route
        path="/user/search"
        element={
          <AuthProvider>
            <Customers />
          </AuthProvider>
        }
      />
      <Route
        path="/user/locations"
        element={
          <AuthProvider>
            <DonorSearch />
          </AuthProvider>
        }
      />
    </Routes>
  </Router>
);

export default RoutesComponent;
