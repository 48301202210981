import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { RootState } from 'src/redux/store';

import authService from 'src/lib/authService';
import { authTokenAvailable, removeToken } from 'src/lib/tokens';

const HANDLERS = {
  INITIALIZE: 'INITIALIZE',
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT',
};

const initialState = {
  isAuthenticated: false,
  isLoading: true,
  user: null,
};

interface State {
  isAuthenticated: boolean;
  isLoading: boolean;
  user: any; // Replace 'any' with the actual type of 'user'
}

interface Action {
  type: string;
  payload?: any; // Replace 'any' with the actual type of 'payload'
}

const handlers = {
  [HANDLERS.INITIALIZE]: (state: State, action: Action) => {
    const user = action.payload;

    return {
      ...state,
      ...// if payload (user) is provided, then is authenticated
      (user
        ? {
            isAuthenticated: true,
            isLoading: false,
            user,
          }
        : {
            isLoading: false,
          }),
    };
  },
  [HANDLERS.SIGN_IN]: (state: State, action: Action) => {
    const user = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  [HANDLERS.SIGN_OUT]: (state: State) => {
    window.sessionStorage.removeItem('authenticated'); // Implement setting of HttpOnly cookies here
    localStorage.removeItem('token');
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  },
};

const reducer = (state: State, action: Action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// The role of this context is to propagate authentication state through the App tree.

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined,
);

interface Props {
  children: React.ReactNode;
}

interface AuthContextType {
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, name: string, password: string) => Promise<never>;
  signOut: () => void;
  isAuthenticated: boolean;
  isLoading: boolean;
  user: any; // Replace 'any' with the actual type of 'user'
}

export const AuthProvider = (props: Props) => {
  const { children } = props;
  const user = useSelector((state: RootState) => state.user.user);
  const [state, dispatch] = useReducer(reducer, initialState);
  const initialized = useRef(false);

  const initialize = async () => {
    // Prevent from calling twice in development mode with React.StrictMode enabled
    if (initialized.current) {
      return;
    }

    initialized.current = true;

    try {
      if (!authTokenAvailable()) {
        dispatch({
          type: HANDLERS.INITIALIZE,
        });
        return;
      }

      authService.getUserFromToken().then((user) => {
        if (!user) {
          dispatch({
            type: HANDLERS.INITIALIZE,
          });
          return;
        }
        dispatch({
          type: HANDLERS.INITIALIZE,
          payload: user,
        });
      });
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    initialize();
  }, []);

  const signIn = async (email: string, password: string) => {
    if (email !== 'demo@devias.io' || password !== 'Password123!') {
      throw new Error('Please check your email and password');
    }

    try {
      window.sessionStorage.setItem('authenticated', 'true'); // Implement setting of HttpOnly cookies here
    } catch (err) {
      console.error(err);
    }

    const user = {
      id: '5e86809283e28b96d2d38537',
      avatar: '/assets/avatars/avatar-anika-visser.png',
      name: 'Anika Visser 2',
      email: 'anika.visser@devias.io',
    };

    dispatch({
      type: HANDLERS.SIGN_IN,
      payload: user,
    });
  };

  const signUp = async (email: string, name: string, password: string) => {
    throw new Error('Sign up is not implemented');
  };

  const signOut = () => {
    removeToken();
    dispatch({
      type: HANDLERS.SIGN_OUT,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signIn,
        signUp,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export const useAuthContext = () => useContext(AuthContext);
