import apiClient from 'src/lib/apiClient';

class donorLocationService {
  getLocations(lat: number, lng: number) {
    return apiClient
      .post('/locations', { lat: lat, lng: lng })
      .then((response) => response.data)
      .catch((error) => {
        // Handle error here
        console.error('Error while getting locations:', error);
        Promise.reject(error);
      });
  }
}

export default new donorLocationService();
