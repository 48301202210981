const stagingConfig = {
  basename: '',
  defaultPath: '/',
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 12,
  signInWithGoogle: {
    clientId: process.env.REACT_APP_STAGING_GOOGLE_CLIENT_ID ?? '',
  },
  apiUrl: process.env.REACT_APP_STAGING_API_BASE_URL,
  googleMapsApiKey: process.env.REACT_APP_STAGING_GOOGLE_MAPS_API_KEY ?? '',
};

const devConfig = {
  basename: '',
  defaultPath: '/',
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 12,
  signInWithGoogle: {
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID ?? '',
  },
  apiUrl: process.env.REACT_APP_API_BASE_URL,
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '',
};

function getConfig() {
  switch (window.location.hostname) {
    case 'myblooddonordev.com':
      return stagingConfig;
    default:
      return devConfig;
  }
}

const config = getConfig();

export default config;
