import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Button,
  Avatar,
  Typography,
  Select,
  SelectChangeEvent,
  MenuItem,
  InputLabel,
  FormControl,
  Input,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import dayjs from 'dayjs';
import { Layout as DashboardLayout } from 'src/layout/dashboard/layout';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import faceImage from '../../assets/imgs/face/face-0.jpg';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/hooks/hooks'; // Replace this with the path to your new useDispatch

import { fetchUserById } from 'src/redux/features/user/userSlice';
import { RootState } from 'src/redux/store';
import { updateUser } from 'src/redux/features/user/userSlice';

interface FormValues {
  id?: string | undefined;
  email?: string | undefined;
  firstName?: string;
  lastName?: string;
  bloodType?: string;
  dateOfLastDonation?: Date | null;
  city?: string | null;
  state?: string | null;
  phone?: number | null;
  medicalHistory?: string | null;
  age?: number | null;
  weight?: number | null;
  gender?: string | null;
  donationPreferences?: string | null;
  availability?: string | null;
  consentForFutureContact?: boolean | null;
}

const UserProfile: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  const [formValues, setFormValues] = useState<FormValues>({});

  useEffect(() => {
    dispatch(fetchUserById());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      setFormValues(user);
    }
  }, [user]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const handleDateChange = (newValue: Date | null) => {
    setFormValues({
      ...formValues,
      dateOfLastDonation: newValue ? dayjs(newValue).toDate() : null, // or .format() if you want to store as a string
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.checked,
    });
  };

  const handleUpdateProfile = () => {
    dispatch(updateUser(formValues));
  };

  return (
    <DashboardLayout>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card>
              <CardHeader title="Edit Profile" />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <InputLabel>First Name</InputLabel>
                      <Input
                        type="text"
                        name="firstName"
                        value={formValues.firstName || ''}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <InputLabel>Last Name</InputLabel>
                      <Input
                        type="text"
                        name="lastName"
                        value={formValues.lastName || ''}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <InputLabel>Blood Type</InputLabel>
                      <Select
                        name="bloodType"
                        value={formValues.bloodType || ''}
                        onChange={handleSelectChange}
                      >
                        <MenuItem value="A+">A+</MenuItem>
                        <MenuItem value="A-">A-</MenuItem>
                        <MenuItem value="B+">B+</MenuItem>
                        <MenuItem value="B-">B-</MenuItem>
                        <MenuItem value="AB+">AB+</MenuItem>
                        <MenuItem value="AB-">AB-</MenuItem>
                        <MenuItem value="O+">O+</MenuItem>
                        <MenuItem value="O-">O-</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <DatePicker
                        label="Date of Last Donation"
                        value={
                          formValues.dateOfLastDonation
                            ? dayjs(formValues.dateOfLastDonation).toDate()
                            : null
                        }
                        onChange={handleDateChange}
                      />
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <InputLabel>City</InputLabel>
                      <Input
                        type="text"
                        name="city"
                        value={formValues.city || ''}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <InputLabel>State</InputLabel>
                      <Input
                        type="text"
                        name="state"
                        value={formValues.state || ''}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {/* todo: use libphonenumber-js to validate input */}
                    <FormControl fullWidth>
                      <InputLabel>Phone Number</InputLabel>
                      <Input
                        type="tel"
                        name="phone"
                        value={formValues.phone || ''}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <InputLabel>Medical History</InputLabel>
                      <Input
                        type="text"
                        name="medicalHistory"
                        value={formValues.medicalHistory || ''}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <InputLabel>Age</InputLabel>
                      <Input
                        type="number"
                        name="age"
                        value={formValues.age || ''}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <InputLabel>Weight</InputLabel>
                      <Input
                        type="number"
                        name="weight"
                        value={formValues.weight || ''}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <InputLabel>Gender</InputLabel>
                      <Select
                        name="gender"
                        value={formValues.gender || ''}
                        onChange={handleSelectChange}
                      >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <InputLabel>Donation Preferences</InputLabel>
                      <Input
                        type="text"
                        name="donationPreferences"
                        value={formValues.donationPreferences || ''}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <InputLabel>Availability</InputLabel>
                      <Input
                        type="text"
                        name="availability"
                        value={formValues.availability || ''}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="consentForFutureContact"
                          checked={formValues.consentForFutureContact || false}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Consent for Future Contact"
                    />
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdateProfile}
                >
                  Update Profile
                </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Avatar
                  alt="User Avatar"
                  src={user && user.photoUrl ? user?.photoUrl : faceImage}
                  style={{ width: '20%', height: 'auto' }}
                />
                <Typography variant="h5">
                  {user?.firstName} {user?.lastName}
                </Typography>
                <Typography variant="subtitle1">{user?.email}</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </DashboardLayout>
  );
};

export default UserProfile;
