import styles from './BoundingDotLoader.module.scss';

const BouncingDotsLoader = (props: any) => {
  return (
    <div className={styles['bouncing-loader']}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default BouncingDotsLoader;
