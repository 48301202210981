import { ThemeProvider } from '@mui/material/styles';
import RoutesComponent from './routes/Routes';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createTheme } from 'src/theme';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import './App.css';

const theme = createTheme();

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <RoutesComponent />
        </div>
        <ToastContainer />
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
