import { useMemo, useEffect, useState } from 'react';
import { Layout as DashboardLayout } from 'src/layout/dashboard/layout';
import GoogleMapReact from 'google-map-react';
import MagnifyingGlassIcon from '@heroicons/react/24/solid/MagnifyingGlassIcon';
import {
  Box,
  Container,
  Card,
  InputAdornment,
  OutlinedInput,
  SvgIcon,
  Unstable_Grid2 as Grid,
  CardContent,
  Typography,
  Button,
} from '@mui/material';
import config from 'src/config';
import { Stack } from '@mui/system';
import { usePlacesWidget } from 'react-google-autocomplete';
import donorLocationService from 'src/lib/donorLocationService';
import MapPinIcon from '@heroicons/react/24/solid/MapPinIcon';
import React from 'react';

declare global {
  interface Window {
    google: any;
  }
}

interface Location {
  id: string;
  name: string;
  openHours: string;
  address: string;
  city: string;
  travelTime: string;
  latitude: number;
  longitude: number;
  openNow?: boolean;
  ratingsTotal: number;
}

const DonorLocationList = ({ locations }: { locations: Location[] }) => {
  return (
    <Stack sx={{ overflowY: 'auto', justifyContent: 'center' }}>
      {locations.map((location) => (
        <Card key={location.id} sx={{ mb: 2, width: '90%' }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid xs={7}>
                <Stack spacing={1}>
                  <Typography
                    variant="h6"
                    color="error"
                    fontWeight="bold"
                    textAlign={'left'}
                  >
                    {location.name}
                  </Typography>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    textAlign={'left'}
                  >
                    {location.openHours}
                  </Typography>
                  <Typography variant="body1" textAlign={'left'}>
                    {location.address}
                  </Typography>
                  <Typography variant="body1" textAlign={'left'}>
                    {location.city}
                  </Typography>
                </Stack>
              </Grid>
              <Grid xs={5} sx={{ alignItems: 'center', height: '100%' }}>
                <Button variant="contained" color="primary">
                  Schedule
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
    </Stack>
  );
};

const LocationSearch = ({
  onPlaceSelected,
}: {
  onPlaceSelected: (location: Location) => void;
}) => {
  const { ref } = usePlacesWidget({
    apiKey: config.googleMapsApiKey,
    onPlaceSelected: (place) => {
      const location: any = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      onPlaceSelected(location);
    },
    options: {
      types: ['(cities)'],
    },
  });

  return (
    <Card sx={{ p: 2 }}>
      <OutlinedInput
        defaultValue=""
        fullWidth
        inputRef={ref}
        placeholder="Search City, State, or Zip Code"
        startAdornment={
          <InputAdornment position="start">
            <SvgIcon color="action" fontSize="small">
              <MagnifyingGlassIcon />
            </SvgIcon>
          </InputAdornment>
        }
        sx={{ maxWidth: 500 }}
      />
    </Card>
  );
};

const AnyReactComponent = ({
  text,
  lat,
  lng,
  key,
}: {
  text: string;
  lat: number;
  lng: number;
  key: string;
}) => (
  <React.Fragment key={key}>
    <MapPinIcon style={{ height: '25px', color: 'red' }} />
  </React.Fragment>
);

const DonorSearch = () => {
  const [isGoogleMapsLoaded, setGoogleMapsLoaded] = useState(false);
  const [locations, setLocations] = useState<Location[]>([]);
  const [latitude, setLatitude] = useState<number>();
  const [longitude, setLongitude] = useState<number>();

  const defaultProps = useMemo(
    () => ({
      center: {
        lat: latitude || 39.961178,
        lng: longitude || -82.998795,
      },
      zoom: 11,
    }),
    [latitude, longitude],
  );

  useEffect(() => {
    // Check if Google Maps API is loaded
    const checkGoogleMaps = () => window.google !== undefined;

    // Set interval to check if Google Maps API has loaded
    const intervalId = setInterval(() => {
      if (checkGoogleMaps()) {
        clearInterval(intervalId);
        setGoogleMapsLoaded(true);
      }
    }, 100);

    return () => clearInterval(intervalId);
  }, []);

  const handlePlaceSelected = (location: any) => {
    donorLocationService
      .getLocations(location.lat, location.lng)
      .then((locations) => {
        setLocations(locations);
        setLatitude(location.lat);
        setLongitude(location.lng);
      });
  };

  return (
    <DashboardLayout>
      <Box component="main" sx={{ flexGrow: 1, py: 2 }}>
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid xs={12} md={6} lg={4}>
              <Stack
                spacing={3}
                sx={{
                  justifyContent: 'center',
                }}
              >
                <LocationSearch onPlaceSelected={handlePlaceSelected} />
                <div style={{ height: '80vh', overflowY: 'auto' }}>
                  <DonorLocationList locations={locations} />
                </div>
              </Stack>
            </Grid>
            <Grid xs={12} md={6} lg={8}>
              <Card>
                <div style={{ height: '85vh', width: '100%' }}>
                  {isGoogleMapsLoaded && (
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: config.googleMapsApiKey }}
                      defaultCenter={defaultProps.center}
                      defaultZoom={defaultProps.zoom}
                      center={defaultProps.center}
                    >
                      {locations.map((location, idx) => (
                        <AnyReactComponent
                          lat={location.latitude}
                          lng={location.longitude}
                          text={location.name}
                          key={location.id}
                        />
                      ))}
                    </GoogleMapReact>
                  )}
                </div>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </DashboardLayout>
  );
};

export default DonorSearch;
