import apiClient from 'src/lib/apiClient';

class authService {
  getTokenFromCode(code: string) {
    return apiClient
      .post('/users/signin/google', { code })
      .then((response) => response.data)
      .catch((error) => {
        // Handle error here
        console.error('Error while getting token from code:', error);
        throw error;
      });
  }

  getUserFromToken() {
    return apiClient
      .get('/user')
      .then((response) => response.data)
      .catch((error) => {
        // Handle error here
        localStorage.removeItem('token');
        console.error('Error while getting user from token:', error);
      });
  }
}

export default new authService();
